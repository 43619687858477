// layout STUFF
.wrap {
  margin: 0 $general-Margin;
  overflow: hidden;
}

.desk-wrap:last-child {
  margin-bottom: $general-Margin-Desktop * 2;
}

main {
  overflow: hidden;
}

img::selection {
  background-color: $color-header-selection;
}

//3d
.threeD {
  position: relative;
}

// .three-model::after{
//   content: ' ';
// }

.three-model {
  // width: 100%;
  // height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

// image / video STUFF
.size-container {
  overflow: hidden;
}

// vimeo and youtube STUFF
.web-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.web-video-container iframe,
.web-video-container object,
.web-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//local videos
div.vid {
  position: relative;
  overflow: hidden;
}

div.vid video {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// image / video mixed:
div.pic img {
  width: 100%;
  max-width: 100%;
}

div.text .vid,
.size-container,
div.text div.gif,
div.text div.pic img {
  display: block;
  margin: $general-Margin-Desktop * 2 auto $general-Margin-Desktop * 2 auto;
  width: 100%;
}

div.text div.gif {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

div.text div.threeD::after,
div.text div.vid.landscape::after,
div.text div.gif::after {
  padding-top: 66.666%;
  display: block;
  content: "";
}

div.text div.vid::after {
  padding-top: 56.25%; //16:9
  display: block;
  content: "";
}

div.text div.threeD.portrait::after,
div.text div.vid.portrait::after,
div.text div.gif.portrait::after {
  padding-top: 150%;
}

div.text .vid.s,
.size-container.s,
div.text div.gif.s,
div.text div.pic.s img {
  width: 75%;
}

div.text .vid.xs,
.size-container.xs,
div.text div.gif.xs,
div.text div.pic.xs img {
  width: 50%;
}

//tablet
@media screen and (min-width: $breakpoint-Tablet) {
  .wrap {
    max-width: 75%;
    // margin: 0 $general-Margin;
    margin: 0 auto;
  }

  .desk-wrap {
    max-width: 75%;
    // margin: 0 $general-Margin;
    margin: 0 auto;
  }

  .desk-wrap:last-child {
    margin-bottom: $general-Margin-Desktop * 4;
  }

  div.text .vid,
  .size-container,
  div.text div.gif,
  div.text div.pic img {
    margin: $general-Margin-Desktop * 4 auto $general-Margin-Desktop * 4 auto;
  }

  div.text .vid.m,
  .size-container.m,
  div.text div.gif.m,
  div.text div.pic.m img {
    width: 75%;
  }

  div.text .vid.s,
  .size-container.s,
  div.text div.gif.s,
  div.text div.pic.s img {
    width: 50%;
  }

  div.text .vid.xs,
  .size-container.xs,
  div.text div.gif.xs,
  div.text div.pic.xs img {
    width: 30%;
  }
}
