.blog-articles {
  display: block;
  position: relative;
  margin-bottom: $general-Margin*3;
}

.blog-articles:hover { //stroke thing
  // border-left: $stroke-Weight solid $color-link;
  // padding-left: $stroke-Margin;
  // margin-left: -1 * $stroke-Margin - $stroke-Weight;
}

.blog-title {
  margin: 0 $general-Margin $general-Margin $general-Margin;
}

.blog-title h3 {
  display: inline;
  hyphens: auto;

  font-size: 3rem;

  background-image: linear-gradient(180deg, transparent 93%, $color-link 0);
  background-size: 0% 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease;
}

.blog-articles:hover h3{
  background-size: 100% 100%;
}

.blog-articles span{
  // font-style: italic;
}

.blog-infos {
  margin: 0 $general-Margin $general-Margin/2 $general-Margin;
}

.blog-datetime {
  // font-size: 1rem;
}

.blog-excerpt {
  hyphens: auto;
  // opacity: 0;
}

.blog-excerpt:hover {
  // opacity: 1;
}


//tablet
@media screen and (min-width: $breakpoint-Tablet) {

  .blog-title {
    margin: 0 0 $general-Margin 0;
  }

  .blog-title h3{
    font-size: 5rem;
  }

  .blog-infos {
    margin: 0 0 $general-Margin/2 0;
  }

}
