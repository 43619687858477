body {
	background-color: $color-background;
}

html, body {
	height: 100%; //?
}

.hidden { //stuff under the carpet
	display: none !important;
	visibility: none !important;
	opacity: none !important;
}

.fadeIn {
	opacity: 0;
	visibility: hidden;
	transition: opacity $fade-speed ease-in-out,
	visibility $fade-speed ease-in-out;
}

.fadedIn {
	opacity: 1;
	visibility: visible;
}

//tablet
@media screen and (min-width: $breakpoint-Tablet) {

	.mobile-only{
		// display: none;
	}

}
