h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-header;
  // font-family: Happy Times;
  font-family: Inter UI;
  // font-family: 'Playfair Display', serif;

  text-decoration: none;
  text-transform: uppercase;
  font-kerning: normal;
  letter-spacing: 0.02em;
}

header h1 {
  max-width: $paragraph-width;
  // margin-left: $text-indent;
  margin-bottom: $general-Margin/2;

  hyphens: auto;
}

h1 {
  font-size: 3rem;
  // padding-bottom: 0.25em;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  // font-size: 1.5rem;
}

//paragraph
a,
div,
p,
span {
  font-family: Inter UI;
  font-size: $font-size-Mobile;
  text-decoration: none;
  color: $color-text;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

.text p {
  // text-indent: 4rem;
}

.text p:first-of-type {
  // text-indent: 0;
}

.text div,
.text p,
.text p a,
.text span,
.text ul,
.text ol,
.text li {
  line-height: 1.25em;
}

.text p,
.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6,
.text ul,
.text ol {
  max-width: $paragraph-width;
  margin: 0 $general-Margin;
  overflow: hidden;
}

.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  margin: $general-Margin-Desktop * 2 $general-Margin $general-Margin / 2
    $general-Margin;
  // padding-left: $text-indent;
}

.blog h1,
.blog h2,
.blog h3,
.blog h4,
.blog h5,
.blog h6 {
  padding-left: $text-indent;
}

.blog-title h3 {
  padding-left: 0;
}

// .text p, //because of text indent no margin bottom!
.text ul,
.text ol,
.text pre {
  margin-bottom: $general-Margin * 2;
}

.text p + p {
  text-indent: $text-indent;
}

.text h1 + p,
.text h2 + p,
.text h3 + p,
.text h4 + p,
.text h5 + p,
.text h6 + p,
.text ul + p,
.text ul p,
.text ol + p,
.text ol p,
.text pre + p {
  text-indent: 0;
}

//about site
.aboutText {
  // margin: $general-Margin-Desktop*2 0;
}

.aboutText a,
.aboutText p {
  font-size: 1.25rem;
}

//link STUFF
.text a {
  text-decoration: underline;
  text-decoration-color: $color-link;
}

a:hover {
  color: $color-link;
}

// special STUFF
.text hr {
  margin: 0 $general-Margin;
  overflow: hidden;
  border-width: $stroke-Weight/2;
}

.text ul {
  // list-style-type: initial;
  padding-left: $text-indent;
  position: relative;
}

.text ul li::before {
  content: $list-sign;
  position: absolute;
  left: 0;
  width: 1.8rem;
  text-align: right;
}

.text ol {
  list-style-type: decimal;
  padding-left: $general-Margin-Desktop;
}

.text blockquote p:last-of-type {
  margin-bottom: 0;
}

.text blockquote + p  {
  font-style: italic;
  margin-bottom: $general-Margin * 2;
}

//code STUFF
.text pre,
.text code {
  background-color: $color-background-code;
  font-family: Courier, monospace;
}

.text code {
  position: relative;
  // bottom: .05em;
}

.text pre > code {
  display: block;
  // margin: 0;
  padding: $general-Margin;
  position: static;
  bottom: auto;
  overflow-x: auto;
}

@supports (-webkit-overflow-scrolling: touch) {
  .text pre > code {
    /* Enables smoother scrolling for code sections on
       mobile (i.e. touch) devices. */
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

//SELECTION STUFF
h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection {
  color: $color-header-selection;
}

span::selection,
a::selection,
ul::selection,
ol::selection,
li::selection,
p::selection,
em::selection,
strong::selection {
  color: $color-text-selection;
}

//tablet
@media screen and (min-width: $breakpoint-Tablet) {
  .text p,
  // .text h1,
  // .text h2,
  // .text h3,
  // .text h4,
  // .text h5,
  // .text h6,
  .text ul,
  .text ol,
  .text hr,
  .text pre {
    margin-left: 0;
    margin-right: 0;
    // overflow: hidden;
  }

  .text h1,
  .text h2,
  .text h3,
  .text h4,
  .text h5,
  .text h6 {
    margin-left: 0;
    margin-right: 0;

    box-sizing: border-box;
    padding-right: $text-indent;
  }
}
