footer.footer {
    // min-height: 40px;
		position: relative;
}

p.footer-copyright {
	float: right;
	margin: $general-Margin 0;
	color: $primary-color-light;
}
