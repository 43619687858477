ul.showcase {
  margin: 44px 0; // make room for last/First project title
}

ul.showcase li.showcase-item {
  // margin: 2rem 0;
  position: relative;
  margin-bottom: $general-Margin-Desktop;
}

ul.showcase a {
  overflow: hidden;
}

div.showcase-image {
  width: 100%;
  line-height: 0;
  // background-size: cover;
  // background-position: center center;
  // background-repeat: no-repeat;
}
// div.showcase-image::after{
//     padding-top: 66.666%;
//     display: block;
//     content: '';
// }

.showcase-caption {
  // position: absolute;
  width: 100%;
  // height: 100%;
  height: 2rem;
  // z-index: 1;
  padding-top: $general-Margin/4;
  padding-left: $general-Margin;

  // pointer-events: none;
  overflow: hidden;

  // opacity: 0;
  // visibility: hidden;
  // transition: opacity ease-in-out 0.3s;
}

.showcase-caption > div {
  //.title-wrap
  // background-color: $color-background;
}

.showcase-caption h3 {
  // opacity: 0;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 1rem;

  // background-image: linear-gradient(180deg, transparent 93%, $color-link 0);
  // background-size: 0% 100%;
  // background-repeat: no-repeat;
  // transition: background-size 0.5s ease;
}

li.showcase-item:hover h3 {
  // background-size: 100% 100%;
  // color: $color-link;
}

.showcase-caption .caption-box {
  // position: absolute;
}
//
// .showcase-caption-1 .caption-box {
//   bottom: 0;
// }
//
// .showcase-caption-2 .caption-box {
//   top: 0;
// }


// .showcase-item:first-of-type .showcase-caption-1,
// .showcase-item:last-of-type .showcase-caption-2{
//   height: 2rem; //same as h3
// }

// .showcase-item.is-Centered .showcase-caption {
//   opacity: 1;
//   visibility: visible;
// }

// .showcase-item.is-Centered .showcase-caption h3 {
//   opacity: 1;
//   transform: translate3d(0,0,0);
// }

// //animation
// @keyframes scroll {
//     0%   {left: -100%;}
//     100% {left: 100%;}
// }

.showcase-caption-1 {
  // background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  // background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
  // background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

.showcase-caption-2 {
  // background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  // background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
  // background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
}

.mq-container {
  // position: relative;
  // margin: 0 auto;
  // padding: 0;
  // width: 700px;
  // height: 100px;
  // background-color: yellow;
  // overflow: hidden;
}

.mq-title-wrap {
  // padding: 0;
  // margin: 0;
  display: inline-block;
  white-space: nowrap;
}

.mq-title {
  display: inline-block;
  // font-size: 60px;
  // margin: 0;
  // padding: 0;
  margin-right: $text-indent;
  // letter-spacing: 0;
}
//tablet
@media screen and (min-width: $breakpoint-Tablet) {
  .showcase-caption {
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // height: 100%;
    // background: none;
    padding-left: 0;
  }
  // .showcase-item:first-of-type .showcase-caption-1,
  // .showcase-item:last-of-type .showcase-caption-2{
  //   height: 100%; //same as h3
  // }
  .showcase-image {
    // margin: $general-Margin-Desktop*5 0;
  }

  .showcase-caption h3 {
    font-size: 1.5rem;
  }

  .showcase-item:first-of-type .showcase-image {
    margin-top: 0;
  }
}
