header.header{
    padding-top: $general-Margin;
    margin-bottom: 50px;
    position: relative;
    z-index: 100;
}

header.header div.grid::after {
   content: " ";
   display: block;
   height: 0;
   clear: both;
}

header .site-title{
    display: inline-block;
}

header .site-title a,
header nav.main-nav {
    text-transform: uppercase;
}

header nav.main-nav {
    float: right;
    width: 20%;
}

a.menu-item {
    display: block;
    line-height: 1.25em;
    text-align: right;
    padding-right: $stroke-Weight + $stroke-Margin;
}

a.menu-item:hover {
    border-right: $stroke-Weight solid $color-link;
    padding-right: $stroke-Margin;
    color: $color-text;
}

a.menu-item.is-active {
    border-right: $stroke-Weight solid $color-text;
    padding-right: $stroke-Margin;
}

//desktop
@media screen and (min-width: $breakpoint-Tablet) {
  header.header {
    padding-top: $general-Margin-Desktop;
  }

  // a.menu-item {
  //     text-align: left;
  //     padding-left: $stroke-Weight + $stroke-Margin;
  // }
  //
  // a.menu-item.is-active {
  //     border-right: 0;
  //     border-left: $stroke-Weight solid $color-text;
  //     padding-left: $stroke-Margin;
  // }
}
