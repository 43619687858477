// colors

$primary-color: #212121;
$primary-color-light: #484848;
$primary-color-dark: #000000;

$primary-color: hsla(230, 18%, 6%, 1);
// $primary-color-light: #484848;
// $primary-color-dark: #000000;

$secondary-color: #f4511b;
$secondary-color-light: #ff844a;
$secondary-color-dark: #b91400;

// $contrary-color: #1bf4f4;
// $contrary-color-light: #4accff;
$contrary-color: $secondary-color;
$contrary-color-light: $secondary-color;

$primary-text-color: #ffffff;

// colors
$color-background: $primary-color;
$color-background-code: $primary-color-light;

$paragraph-width: 600px;
$list-sign: "→";

$color-text: #ffffff;
$color-header: $color-text;

$color-header-selection: $contrary-color;
$color-text-selection: $contrary-color-light;

$color-link: $secondary-color;
$color-link-light: $secondary-color-light;
// $color-link-dark: #000000;


$breakpoint-Tablet: 769px;
$breakpoint-Desktop: 1024px;

$general-Margin: 12px;
$general-Margin-Desktop: 30px;
$stroke-Margin: 5px;

$stroke-Weight: 2px;

$font-size-Mobile: 1rem;
$text-indent: 2rem;
// $font-size-Desktop: $font-size-Mobile;

//animation stuff
$fade-speed: 0.5s;
