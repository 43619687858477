// ---------------INTER UI---------------

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 400;
  src: url("../fonts/Inter-UI-Regular.woff2") format("woff2"),
       url("../fonts/Inter-UI-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 400;
  src: url("../fonts/Inter-UI-Italic.woff2") format("woff2"),
       url("../fonts/Inter-UI-Italic.woff") format("woff");
}

// @font-face {
//   font-family: 'Inter UI';
//   font-style:  normal;
//   font-weight: 500;
//   src: url("../fonts/Inter-UI-Medium.woff2") format("woff2"),
//        url("../fonts/Inter-UI-Medium.woff") format("woff");
// }
// @font-face {
//   font-family: 'Inter UI';
//   font-style:  italic;
//   font-weight: 500;
//   src: url("../fonts/Inter-UI-MediumItalic.woff2") format("woff2"),
//        url("../fonts/Inter-UI-MediumItalic.woff") format("woff");
// }

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-weight: 700;
  src: url("../fonts/Inter-UI-Bold.woff2") format("woff2"),
       url("../fonts/Inter-UI-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-weight: 700;
  src: url("../fonts/Inter-UI-BoldItalic.woff2") format("woff2"),
       url("../fonts/Inter-UI-BoldItalic.woff") format("woff");
}

// @font-face {
//   font-family: 'Inter UI';
//   font-style:  normal;
//   font-weight: 900;
//   src: url("Inter-UI-Black.woff2") format("woff2"),
//        url("Inter-UI-Black.woff") format("woff");
// }
// @font-face {
//   font-family: 'Inter UI';
//   font-style:  italic;
//   font-weight: 900;
//   src: url("Inter-UI-BlackItalic.woff2") format("woff2"),
//        url("Inter-UI-BlackItalic.woff") format("woff");
// }


// ---------------Messina   ---------------

// @font-face {
//     font-family: 'Messi';
//     src: url('../fonts/MessinaSansWeb-Regular.woff2') format('woff2');
//     font-weight: normal;
//     font-style: normal;
// }
//
// @font-face {
//     font-family: 'Messi';
//     src: url('../fonts/MessinaSansWeb-RegularItalic.woff2') format('woff2');
//     font-weight: normal;
//     font-style: italic;
// }
//
// @font-face {
//     font-family: 'Messi';
//     src: url('../fonts/MessinaSansWeb-SemiBold.woff2') format('woff2');
//     font-weight: 500;
//     font-style: normal;
// }
//
// @font-face {
//     font-family: 'Messi';
//     src: url('../fonts/MessinaSansWeb-SemiBoldItalic.woff2') format('woff2');
//     font-weight: 500;
//     font-style: italic;
// }

//---------------Sectra    ---------------

// @font-face {
//   font-family: 'GT-Sectra';
//   font-style:  normal;
//   font-weight: 500;
//   src: url("../fonts/GT-Sectra-Regular.woff") format("woff");
// }
//
// @font-face {
//   font-family: 'GT-Sectra';
//   font-style:  italic;
//   font-weight: 500;
//   src: url("../fonts/GT-Sectra-Regular-Italic.woff") format("woff");
// }

// @import url('https://fonts.googleapis.com/css?family=Playfair+Display:700');

//---------------Happy Times    ---------------

// @font-face {
//     font-family: 'Happy Times';
//     font-weight: normal;
//     font-style: italic;
//     src: url('../fonts/happy-times-at-the-ikob_italic-webfont.woff2') format('woff2'),
//          url('../fonts/happy-times-at-the-ikob_italic-webfont.woff') format('woff'),
//          url('../fonts/happy-times-at-the-ikob_italic.ttf') format('truetype');
// }
//
// @font-face {
//     font-family: 'Happy Times';
//     font-weight: normal;
//     font-style: normal;
//     src: url('../fonts/happy-times-at-the-ikob-webfont.woff2') format('woff2'),
//          url('../fonts/happy-times-at-the-ikob-webfont.woff') format('woff'),
//          url('../fonts/happy-times-at-the-ikob.ttf') format('truetype');
// }
